import { Layout as BaseLayout } from 'antd';
import { FC, Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { useUserController_getCurrentUser } from '@api-client/generated/UserController/getCurrentUser';
import { Loader } from '@components';
import { OnboardingProvider } from '@context';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAccount, useOnboarding } from '@hooks';
import { layout } from '@theme';
import { mediaWidth } from '@ui-kit/theme/mediaWidth';
import { checkPublicRoute } from '@utils';

import { Header } from './Header/Header';
import { Sidebar } from './Sidebar/Sidebar';

const Container = styled(BaseLayout, {
  shouldForwardProp: (prop) => prop !== 'withPadding',
})<{
  withPadding?: boolean;
}>`
  min-height: calc(100vh - 72px);
  margin-left: ${({ withPadding }) =>
    withPadding ? `${layout.sidebarWidth}px` : 0};
  margin-top: 72px;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
`;

const Content = styled(BaseLayout.Content)`
  padding: 50px;

  ${mediaWidth.laptopM(css`
    padding: 0;
  `)};
`;

const Layout: FC = () => {
  const navigate = useNavigate();

  const { steps, isLoading } = useOnboarding();
  const { isLogged, company, userAccess, setAccount } = useAccount();

  const { data: user } = useUserController_getCurrentUser();

  const { pathname } = useLocation();

  useEffect(() => {
    if (user) {
      setAccount(user);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (isLogged) {
      if (company?.status === 'in_service') {
        navigate(userAccess?.transactions ? '/transactions' : '/documents');
      } else {
        if (!checkPublicRoute(pathname)) {
          navigate('/start');
        }
      }
    }
  }, [company?.status, isLogged, navigate, pathname, userAccess?.transactions]);

  const hasSidebar = !isLoading && !steps.COMPANY_NAME.active;

  return (
    <BaseLayout>
      <Header />

      <Container withPadding={hasSidebar}>
        {hasSidebar && <Sidebar />}

        <Suspense fallback={<Loader />}>
          <Content>{isLoading ? <Loader /> : <Outlet />}</Content>
        </Suspense>
      </Container>
    </BaseLayout>
  );
};

export const LayoutPublic = () => (
  <OnboardingProvider>
    <Layout />
  </OnboardingProvider>
);
