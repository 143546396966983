import { TextProps } from 'antd/lib/typography/Text';
import { FC } from 'react';

import { useAccount } from '@hooks';
import { formatAmount } from '@utils';
import { Currency, Language } from '@constants';

import * as S from './styled';

type AmountProps = {
  /** Amount to show */
  amount: number;
  /** Currency code. It's not optional and you should take it from the entity you work with */
  currencyCode: Currency;
  className?: string;
  withColors?: boolean;
} & TextProps;

const Amount: FC<AmountProps> = ({
  withColors,
  currencyCode,
  className,
  amount,
  ...rest
}) => {
  const { account } = useAccount();

  amount ??= 0;

  return (
    <S.Amount
      className={className}
      type={
        withColors && amount !== 0
          ? amount > 0
            ? 'success'
            : 'danger'
          : undefined
      }
      {...rest}
    >
      {formatAmount(amount, account?.locale as Language, currencyCode)}
    </S.Amount>
  );
};

export default Amount;
