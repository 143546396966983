import styled from '@emotion/styled';
import { Layout } from 'antd';
import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { Loader } from '@components';

const Container = styled(Layout.Content)`
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.bgLightBlue200};
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const LayoutFullPage: FC = () => (
  <Container>
    <Suspense fallback={<Loader />}>
      <Outlet />
    </Suspense>
  </Container>
);
