import { message } from 'antd';
import pdfMake from 'pdfmake/build/pdfmake';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@ui-kit/Button/Button';
import { useAccount } from '@hooks';
import { useDocumentController_updateOneById } from '@api-client/generated/DocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';

import InvoiceModalConfirmFinalize from '../../modals/InvoiceModalConfirmFinalize';
import { getDocumentDefinition } from './helpers';
import * as S from './styled';

type UpdateDocumentDto = Schemas.UpdateDocumentDto;

type InvoicePDFMakeProps = {
  document: Schemas.Document;
  company: Schemas.Company;
};

type UpdateDocumentParams = {
  blob: Blob;
  isDraft?: boolean;
  afterRedirect?: boolean;
};

pdfMake.fonts = {
  Roboto: {
    normal:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
    bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
    italics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
    bolditalics:
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf',
  },
};

const InvoicePDFMake = ({ company, document }: InvoicePDFMakeProps) => {
  const { companyId, account } = useAccount();
  const navigate = useNavigate();

  const [messageApi, contextHolderMessage] = message.useMessage();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const { mutate: updateDocument } = useDocumentController_updateOneById();

  const handleUpdateDocument = useCallback(
    ({ blob, isDraft, afterRedirect = true }: UpdateDocumentParams) => {
      const requestParameter = {
        companyId: companyId!,
        id: document.id!,
      };
      const formData = new FormData();
      const documentName =
        document.name || t('invoiceGenerator.document.name')();

      formData.append('file', blob, `${documentName}.pdf`);
      formData.append('fileName', `${documentName}.pdf`);
      formData.append('name', documentName);

      updateDocument(
        {
          parameter: requestParameter,
          requestBody: formData as UpdateDocumentDto,
        },
        {
          onSuccess: () => {
            updateDocument(
              {
                parameter: requestParameter,
                requestBody: !isDraft
                  ? ({
                      ...document,
                      documentMetadata: {
                        ...document.documentMetadata,
                        isReviewed: true,
                        isFinalized: true,
                      },
                    } as UpdateDocumentDto)
                  : {},
              },
              {
                onSuccess: () => {
                  if (afterRedirect) {
                    navigate(`/invoices/${document.id}`);
                  }

                  setIsOpenConfirm(false);
                },
              },
            );
          },
        },
      );
    },
    [companyId, document, navigate, updateDocument],
  );

  const handleGeneratePdf = useCallback(
    ({
      isDraft = false,
      afterRedirect = true,
    }: Omit<UpdateDocumentParams, 'blob'> = {}) => {
      const documentDefinition = getDocumentDefinition({
        locale: account?.locale,
        document,
        company,
      });

      pdfMake
        .createPdf(documentDefinition)
        .getBlob((blob) =>
          handleUpdateDocument({ blob, isDraft, afterRedirect }),
        );
    },
    [account?.locale, company, document, handleUpdateDocument],
  );

  useEffect(() => {
    handleGeneratePdf({
      isDraft: true,
      afterRedirect: false,
    });
  }, [handleGeneratePdf]);

  const handleConfirmFinalize = () => {
    if (!document.documentMetadata?.number) {
      return messageApi.error(
        t('invoiceGenerator.errorMessages.emptyNumber')(),
      );
    }

    if (
      !document.documentMetadata?.issueDate ||
      !document.documentMetadata?.dueDate ||
      !document.documentMetadata?.deliveryDate
    ) {
      return messageApi.error(t('invoiceGenerator.errorMessages.emptyDates')());
    }

    if (!document?.contact) {
      return messageApi.error(t('invoiceGenerator.errorMessages.emptyTo')());
    }

    if (!company.details?.vatId) {
      return messageApi.error(t('invoiceGenerator.errorMessages.emptyFrom')());
    }

    if (!document.documentMetadata?.items?.length) {
      return messageApi.error(t('invoiceGenerator.errorMessages.emptyItems')());
    }

    setIsOpenConfirm(true);
  };

  return (
    <>
      {contextHolderMessage}

      <InvoiceModalConfirmFinalize
        open={isOpenConfirm}
        onConfirm={() => handleGeneratePdf()}
        onCancel={() => setIsOpenConfirm(false)}
      />

      <S.ButtonsGroup align="center" gap={8}>
        <Button
          type="primary"
          onClick={() =>
            handleGeneratePdf({
              isDraft: true,
            })
          }
          block
        >
          {t('invoiceGenerator.draft.buttonSaveDraft')()}
        </Button>

        <Button type="primary" onClick={handleConfirmFinalize} block>
          {t('invoiceGenerator.draft.buttonFinalize')()}
        </Button>
      </S.ButtonsGroup>
    </>
  );
};

export default InvoicePDFMake;
