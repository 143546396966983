import { Spin } from 'antd';
import { ComponentType, lazy, useMemo } from 'react';
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
  useNavigate,
  useRouteError,
} from 'react-router-dom';

import { AccessDenied } from '@entities/errorBoundary/AccessDenied';
import { ErrorFallback } from '@entities/errorBoundary/ErrorFallback';
import { LayoutFullPage } from '@layouts/LayoutFullPage';
import { LayoutProtected } from '@layouts/LayoutProtected';
import { LayoutPublic } from '@layouts/LayoutPublic';
import { useAccount } from '@hooks';
import { filteredRoutes } from '@utils';
import { Schemas } from '@api-client/generated/types';

const lazyLoad = <P extends object>(
  componentImport: () => Promise<{ default: ComponentType<P> }>,
) =>
  lazy(() =>
    componentImport().catch((error) => {
      console.error('Error loading component:', error);
      throw error;
    }),
  );

const AcceptInvitation = lazyLoad(() => import('@pages/AcceptInvitation'));
const Accounts = lazyLoad(() => import('@pages/Accounts'));
const CSVImports = lazyLoad(() => import('@pages/CSVImports'));
const Categories = lazyLoad(() => import('@pages/Categories'));
const ConfirmEmail = lazyLoad(() => import('@pages/ConfirmEmail'));
const ConnectBank = lazyLoad(() => import('@pages/ConnectBank'));
const Contacts = lazyLoad(() => import('@pages/Contacts'));
const DocumentDetails = lazyLoad(() => import('@pages/DocumentDetails'));
const Documents = lazyLoad(() => import('@pages/Documents'));
const Invoices = lazyLoad(() => import('@pages/Invoices'));
const ForgotPassword = lazyLoad(() => import('@pages/ForgotPassword'));
const ForgotPasswordMessage = lazyLoad(
  () => import('@pages/ForgotPasswordMessage'),
);
const Login = lazyLoad(() => import('@pages/Login'));
const InvoiceGenerator = lazyLoad(() => import('@pages/InvoiceGenerator'));
const Legal = lazyLoad(() => import('@pages/Legal'));
const NotFound = lazyLoad(() => import('@pages/NotFound'));
const Onboarding = lazyLoad(() => import('@pages/Onboarding'));
const Payroll = lazyLoad(() => import('@pages/Payroll'));
const Payslips = lazyLoad(() => import('@pages/Payslips'));
const PrivacyPolicy = lazyLoad(() => import('@pages/PrivacyPolicy'));

const Projects = lazyLoad(() => import('@pages/Projects'));
const Report = lazyLoad(() => import('@pages/Report'));
const ResetPassword = lazyLoad(() => import('@pages/ResetPassword'));
const Settings = lazyLoad(() => import('@pages/Settings'));
const SettingsBilling = lazyLoad(() => import('@pages/SettingsBilling'));
const SettingsCompany = lazyLoad(() => import('@pages/SettingsCompany'));
const SettingsPersonal = lazyLoad(() => import('@pages/SettingsPersonal'));
const SettingsTeamMembers = lazyLoad(
  () => import('@pages/SettingsTeamMembers'),
);
const Taxes = lazyLoad(() => import('@pages/Taxes'));
const TaxesDetails = lazyLoad(() => import('@pages/TaxesDetails'));
const TransactionDetails = lazyLoad(() => import('@pages/TransactionDetails'));
const Transactions = lazyLoad(() => import('@pages/Transactions'));
const TransactionsImport = lazyLoad(() => import('@pages/TransactionsImport'));
const Rules = lazyLoad(() => import('@pages/Rules'));
const Tasks = lazyLoad(() => import('@pages/Tasks'));

function DefaultErrorComponent({ fullPage }: { fullPage?: boolean }) {
  const error = useRouteError();
  const navigate = useNavigate();
  const stack = error instanceof Error ? error.stack : undefined;

  return (
    <ErrorFallback
      error={error}
      componentStack={stack}
      fullPage={fullPage}
      onClick={() => navigate(-1)}
    />
  );
}

const createRoutes = (
  isLogged: boolean,
  userAccess: Schemas.AccessRights | null,
) => {
  const appRoutes: RouteObject[] = [
    {
      path: '/',
      element: <LayoutProtected />,
      children: [
        {
          path: '/accounts',
          element: <Accounts />,
        },
        {
          path: '/documents',
          element: <Documents />,
        },
        {
          path: '/documents/:id',
          element: <DocumentDetails />,
        },
        {
          path: '/transactions',
          element: <Transactions />,
        },
        {
          path: '/transactions/:id',
          element: <TransactionDetails />,
        },
        {
          path: '/transactions-import/:id',
          element: <TransactionsImport />,
        },
        {
          path: '/csv-imports',
          element: <CSVImports />,
        },
        {
          path: '/csv-imports/:id',
          element: <CSVImports />,
        },
        {
          path: '/projects',
          element: <Projects />,
        },
        {
          path: '/contacts',
          element: <Contacts />,
        },
        {
          path: '/contacts/:id',
          element: <Contacts />,
        },
        {
          path: '/report',
          element: <Report />,
        },
        {
          path: '/taxes',
          element: <Taxes />,
        },
        {
          path: '/taxes/:id',
          element: <TaxesDetails />,
        },
        {
          path: '/categories',
          element: <Categories />,
        },
        {
          path: '/legal',
          element: <Legal />,
        },
        {
          path: '/invoices',
          element: <Invoices />,
        },
        {
          path: '/invoices/new/:id',
          element: <InvoiceGenerator />,
        },
        {
          path: '/invoices/:id',
          element: <Invoices />,
        },
        {
          path: '/chat',
          element: <Tasks />,
        },
        {
          path: '/tasks/:id',
          element: <Tasks />,
        },
        {
          path: '/rules',
          element: <Rules />,
        },
        {
          path: '/payslips',
          element: <Payslips />,
        },
        {
          path: '/payslips/:id',
          element: <Payslips />,
        },
        {
          path: '/payroll',
          element: <Payroll />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutProtected greyBackground />,
      children: [
        {
          path: '/settings',
          element: <Settings />,
        },
        {
          path: '/settings/personal',
          element: <SettingsPersonal />,
        },
        {
          path: '/settings/company',
          element: <SettingsCompany />,
        },
        {
          path: '/settings/billing',
          element: <SettingsBilling />,
        },
        {
          path: '/settings/team-members',
          element: <SettingsTeamMembers />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutProtected noContentPadding />,
      children: [
        {
          path: '/transactions/:id',
          element: <TransactionDetails />,
        },
        {
          path: '/transactions-import/:id',
          element: <TransactionsImport />,
        },
      ],
    },
    {
      path: '/',
      element: <LayoutFullPage />,
      children: [
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/forgot-password',
          element: <ForgotPassword />,
        },
        {
          path: '/forgot-password/message',
          element: <ForgotPasswordMessage />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/accept-invitation',
          element: <AcceptInvitation />,
        },
        {
          path: '/privacy-policy',
          element: <PrivacyPolicy />,
        },
        {
          path: '/confirm-email',
          element: <ConfirmEmail />,
        },
        isLogged
          ? {
              path: '/connect-bank',
              element: <ConnectBank />,
            }
          : {},
      ],
    },
    {
      path: '/',
      element: <LayoutPublic />,
      children: [
        {
          path: '/start',
          element: <Onboarding />,
        },
      ],
    },
    isLogged
      ? {
          path: '*',
          element: <LayoutProtected />,
          children: [
            {
              path: '*',
              element: <NotFound />,
            },
          ],
        }
      : {
          path: '*',
          element: <LayoutFullPage />,
          children: [
            {
              path: '*',
              element: <Login />,
            },
          ],
        },
  ];

  appRoutes.forEach((route) => {
    route.children?.forEach((child) => {
      child.errorElement = <DefaultErrorComponent />;
    });
  });

  const removePaths: Record<string, string[]> = {
    transactions: ['transactions'],
    companyData: ['legal'],
    userManagement: ['team-members'],
    reports: ['report'],
    payroll: ['payroll'],
    incomesExpences: ['expences', 'invoicing'],
  };

  const routes = filteredRoutes(
    userAccess,
    appRoutes,
    removePaths,
    <AccessDenied />,
  );

  return [
    {
      path: '/',
      errorElement: <DefaultErrorComponent />,
      children: routes,
    },
  ];
};

const AppRouter = () => {
  const { isLogged, userAccess } = useAccount();

  const routes = useMemo(
    () => createRoutes(isLogged, userAccess),
    [isLogged, userAccess],
  );

  const router = createBrowserRouter(routes, {
    basename: import.meta.env.VITE_ROUTE_BASE_URL || '/',
  });

  return <RouterProvider router={router} fallbackElement={<Spin />} />;
};

export default AppRouter;
