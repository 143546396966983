import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Inner = styled(Flex)`
  .ui-form-item {
    .ui-form-item-label {
      label {
        font-size: 12px;
      }
    }
  }
`;
