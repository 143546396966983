import { Button, List, Popover, type PopoverProps } from 'antd';
import { ListProps } from 'antd/lib';
import { PropsWithChildren, useId, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Amount, SearchControl } from '@entities';
import { Scrollbar } from '@components';
import { useInfiniteScroll } from '@hooks/useInfiniteScroll';
import { DEFAULT_CURRENCY_CODE, DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { useModalManager } from '@context/ModalManager/useModalManager';

import { getTransactionsCountString, Project } from '..';
import { useProjectList } from '../hooks';
import ProjectForm from '../ProjectForm/ProjectForm';
import * as S from './styled';

type ProjectPopupListProps = {
  width?: number;
  selectedId?: string | null;
  onSelect: (projectId: string) => void;
  popoverProps?: PopoverProps;
  listProps?: ListProps<Project>;
} & PropsWithChildren;

const ProjectPopupList = (props: ProjectPopupListProps) => {
  const projectCreateModalId = useId();

  const modalManager = useModalManager();

  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const isProjectCreateVisible = modalManager.isOpen(projectCreateModalId);

  const { data, isPending, isRefetching } = useProjectList(
    {
      page: currentPage,
      term: searchValue,
      status: 'active',
      perPage: 10,
    },
    props.popoverProps?.open,
  );

  const hasNextPage = currentPage < (data?.metadata.totalPages || 1);

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: isPending || isRefetching,
    onLoadMore: () => setCurrentPage(currentPage + 1),
    hasNextPage,
  });

  const handleSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  const handleAddNewProjectButtonClick = () => {
    modalManager.push(projectCreateModalId);
    setSearchValue('');
  };

  const handleSelectProject = (projectId: string) => {
    props.onSelect(projectId);

    setSearchValue('');
  };

  const handleChangePopup = (status: boolean) => {
    if (!status) {
      setSearchValue('');
    }
  };

  const handleModalAfterSubmit = (projectId: string) => {
    modalManager.pop(projectCreateModalId);
    handleSelectProject(projectId);
  };

  return (
    <>
      <ProjectForm
        isOpened={isProjectCreateVisible}
        actionType="create"
        onAfterSubmit={handleModalAfterSubmit}
        onCancel={() => modalManager.pop(projectCreateModalId)}
      />

      <Popover
        content={
          <S.Content width={props.width} gap={4} vertical>
            <S.Inner>
              <SearchControl
                onChange={(e) => handleSearch(e.target.value)}
                fitWidth
              />
            </S.Inner>

            <S.Projects>
              <Scrollbar ref={rootRef} height={276} withoutPadding>
                <List
                  dataSource={data?.records}
                  renderItem={(project) => (
                    <S.Item
                      selected={props.selectedId === project.id}
                      onClick={() => handleSelectProject(project.id)}
                    >
                      <S.ItemName>{project.name}</S.ItemName>
                      <S.ItemCount>
                        {getTransactionsCountString(project)}
                      </S.ItemCount>
                      <Amount
                        amount={project.totalAmount}
                        currencyCode={DEFAULT_CURRENCY_CODE}
                      />
                    </S.Item>
                  )}
                  {...props.listProps}
                ></List>

                {hasNextPage && <div ref={sentryRef} />}
              </Scrollbar>
            </S.Projects>

            <S.Inner>
              <Button
                onClick={handleAddNewProjectButtonClick}
                size="small"
                block
              >
                {t('transactionsPage.projectActions.createProject')()}
              </Button>
            </S.Inner>
          </S.Content>
        }
        trigger="click"
        placement="bottom"
        arrow={false}
        onOpenChange={handleChangePopup}
        {...props.popoverProps}
      >
        {props.children}
      </Popover>
    </>
  );
};

export default ProjectPopupList;
