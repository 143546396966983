import styled from '@emotion/styled';
import { Layout } from 'antd';

export const Header = styled(Layout.Header)`
  height: 72px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
`;

export const DevText = styled.span`
  font-weight: 700;
  margin-left: 26px;
`;

export const UserName = styled.span`
  font-weight: 600;
`;

export const Logout = styled.a`
  color: ${({ theme }) => theme.colors.white};
  margin-left: 80px;

  &:hover {
    opacity: 0.75;
  }
`;
