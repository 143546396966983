import styled from '@emotion/styled';
import { Flex } from 'antd';

import IconEditUnderline from '@assets/icons/edit-underline-colored.svg';

export const Card = styled(Flex)`
  width: 830px;
  min-height: 1200px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 10px;
  border: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  padding: 50px;
  font-weight: 500;

  .ui-btn-link {
    width: 20px;
    height: 20px;
  }
`;

export const Name = styled.div`
  width: 130px;
`;

export const Table = styled(Flex)`
  margin: 60px 0;

  .ui-table {
    .ui-table-container {
      &:after {
        display: none;
      }
    }

    .ui-table-content {
      overflow: inherit !important;
    }

    .ui-table-thead {
      tr {
        height: 32px;

        .ui-table-cell {
          color: ${({ theme }) => theme.colors.text400};
          padding-inline: 8px;
          font-size: 13px;
        }
      }
    }

    .ui-table-tbody {
      font-size: 12px;

      .ui-table-row {
        cursor: pointer;
        position: relative;

        &:after {
          content: '';
          width: 24px;
          height: 24px;
          background: url(${IconEditUnderline}) no-repeat center / contain;
          position: absolute;
          top: 16px;
          right: -36px;
          transition: opacity 0.12s;
          opacity: 0;
        }

        &:hover {
          &:after {
            opacity: 1;
          }
        }

        .ui-table-cell {
          padding-inline: 8px;

          .ui-typography {
            font-size: 12px;
          }
        }
      }

      .ui-table-placeholder {
        display: none;
      }
    }
  }
`;

export const ExtraText = styled.div`
  color: ${({ theme }) => theme.colors.grey500};
  font-size: 12px;
  padding-left: 16px;
  margin-top: 8px;
`;

export const ColumnTitle = styled.div`
  white-space: nowrap;
`;
