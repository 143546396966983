import { Modal, type ModalProps } from 'antd';
import { PropsWithChildren } from 'react';

import * as S from './styled';

const InvoiceModalWrapper = ({
  children,
  ...props
}: PropsWithChildren<ModalProps>) => (
  <Modal width={580} footer={null} destroyOnClose centered {...props}>
    <S.Inner gap={24} vertical>
      {children}
    </S.Inner>
  </Modal>
);

export default InvoiceModalWrapper;
