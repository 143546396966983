import dayjs from 'dayjs';
import { ContentStack, TDocumentDefinitions } from 'pdfmake/interfaces';

import { colors } from '@theme';
import { formatAmount, getAddressLine } from '@utils';
import { Schemas } from '@api-client/generated/types';
import {
  DEFAULT_CURRENCY_CODE,
  Language,
  VAT_DEFAULT_PERCENT,
  VAT_MIDDLE_PERCENT,
} from '@constants';

import {
  getAmountByDefaultTaxRate,
  getAmountByMiddleTaxRate,
  getSubTotalAmount,
  getTotalAmount,
  hasIntraEU,
} from '../../document/InvoiceDocumentCard/helpers';

type DocumentContent = TDocumentDefinitions['content'];

type DocumentDefinition = {
  document: Schemas.Document;
  company: Schemas.Company;
  locale?: string;
};

type DocumentHeader = {
  number: string;
  companyName: string;
  issueDate: string | null;
  dueDate: string | null;
  deliveryDate: string | null;
};

type DocumentFooter = {
  bankData: Schemas.BankData;
  contactPerson: Schemas.ContactPerson;
};

type DocumentFrom = {
  companyName: string;
  details: Schemas.CompanyDetails;
};

type DocumentTableItems = {
  items: Schemas.InvoiceItem[];
  currency: Schemas.DocumentMetadata['currency'];
  locale?: Language;
};

type DocumentTotal = {
  items: Schemas.InvoiceItem[];
  currency: Schemas.DocumentMetadata['currency'];
  locale?: Language;
};

const defaultStyle: TDocumentDefinitions['defaultStyle'] = {
  color: colors.text500,
  fontSize: 11,
  lineHeight: 1.4,
};

const allStyles: TDocumentDefinitions['styles'] = {
  textLeft: {
    fontSize: 9,
    alignment: 'left',
    margin: [0, 0, 0, 10],
  },
  textRight: {
    fontSize: 9,
    alignment: 'right',
    margin: [0, 0, 0, 10],
  },
};

const createDocumentHeader = ({
  number,
  companyName,
  issueDate,
  dueDate,
  deliveryDate,
}: DocumentHeader): TDocumentDefinitions['header'] => ({
  columns: [
    {
      stack: [
        {
          text: `${t('invoiceGenerator.document.name')()} ${number}`,
          style: {
            fontSize: 21,
            bold: true,
          },
        },
        {
          text: `${t('invoiceGenerator.document.issuedOn')()} ${issueDate ? dayjs(issueDate).format('DD.MM.YYYY') : ''}`,
        },
        {
          text: `${t('invoiceGenerator.document.dueDate')()} ${dueDate ? dayjs(dueDate).format('DD.MM.YYYY') : ''}`,
        },
        {
          text: `${t('invoiceGenerator.document.deliveryDate')()} ${deliveryDate ? dayjs(deliveryDate).format('DD.MM.YYYY') : ''}`,
        },
      ],
      alignment: 'left',
    },
    {
      stack: [
        {
          text: companyName,
          style: {
            fontSize: 13,
            bold: true,
          },
        },
      ],
      alignment: 'right',
    },
  ],
  margin: [40, 30, 40, 0],
});

const createDocumentTo = (
  contact: Schemas.Document['contact'],
): ContentStack => ({
  stack: [
    {
      text: t('invoiceGenerator.document.to')(),
      style: {
        lineHeight: 1.6,
        fontSize: 17,
        bold: true,
      },
    },
    {
      text: contact?.name || '',
      style: {
        lineHeight: 1.6,
        fontSize: 13,
        bold: true,
      },
    },
    {
      text: contact?.vatId
        ? `${t('invoiceGenerator.document.vatId')()}: ${contact.vatId}`
        : '',
    },
    {
      text: contact?.taxNumber
        ? `${t('invoiceGenerator.document.taxNumber')()}: ${contact.taxNumber}`
        : '',
    },
    {
      text: contact?.billingAddress
        ? getAddressLine(contact.billingAddress)
        : '',
    },
  ],
});

const createDocumentFrom = ({
  companyName,
  details,
}: DocumentFrom): ContentStack => ({
  stack: [
    {
      text: t('invoiceGenerator.document.from')(),
      style: {
        lineHeight: 1.6,
        fontSize: 17,
        bold: true,
      },
    },
    {
      text: companyName,
      style: {
        lineHeight: 1.6,
        fontSize: 13,
        bold: true,
      },
    },
    {
      text: `${t('invoiceGenerator.document.vatId')()}: ${details?.vatId || ''}`,
    },
    {
      text: `${t('invoiceGenerator.document.permitNumber')()}: ${details?.businessLicenseNumber || ''}`,
    },
    {
      text: `${t('invoiceGenerator.document.registrationNumber')()}: ${details?.rscNumber || ''}`,
    },
    {
      text: details?.address ? getAddressLine(details.address) : '',
    },
  ],
});

const createDocumentExtraText = (
  items: Schemas.InvoiceItem[],
): DocumentContent => ({
  text: hasIntraEU(items)
    ? t('invoiceGenerator.document.extraTextReverseCharge')()
    : '',
  style: {
    fontSize: 9,
    color: colors.grey500,
  },
  marginBottom: 16,
});

const mappingTableItems = ({
  items = [],
  currency,
  locale,
}: DocumentTableItems) => {
  const textStyle = {
    fontSize: 9,
    alignment: 'right',
  };

  return items.map((item) => [
    {
      stack: [
        {
          text: item.name,
          style: {
            bold: true,
          },
        },
        {
          text: item.description || '',
          style: {
            ...textStyle,
            alignment: 'left',
          },
        },
      ],
    },
    {
      text: formatAmount(
        item.unitPrice,
        locale,
        currency || DEFAULT_CURRENCY_CODE,
      ),
      style: textStyle,
    },
    {
      text: `${(item.taxRate || 0) * 100}%`,
      style: textStyle,
    },
    {
      text: `${item.quantity} ${item.unit}`,
      style: textStyle,
    },
    {
      text: formatAmount(
        item.totalPrice,
        locale,
        currency || DEFAULT_CURRENCY_CODE,
      ),
      style: textStyle,
    },
  ]);
};

const createDocumentTableItems = ({
  items = [],
  currency,
  locale,
}: DocumentTableItems): DocumentContent => {
  const textStyle = {
    fontSize: 9,
    color: colors.grey500,
    alignment: 'right',
  };

  return {
    table: {
      headerRows: 1,
      widths: [180, 90, '*', '*', '*'],
      body: [
        [
          {
            text: t('invoiceGenerator.document.tableColumns.description')(),
            style: { ...textStyle, alignment: 'left' },
          },
          {
            text: t('invoiceGenerator.document.tableColumns.price')(),
            style: textStyle,
          },
          {
            text: t('invoiceGenerator.document.tableColumns.rate')(),
            style: textStyle,
          },
          {
            text: t('invoiceGenerator.document.tableColumns.quantity')(),
            style: textStyle,
          },
          {
            text: t('invoiceGenerator.document.tableColumns.total')(),
            style: textStyle,
          },
        ],
        ...mappingTableItems({
          items,
          currency,
          locale,
        }),
      ],
    },
    margin: [0, 16, 0, 16],
    layout: 'lightHorizontalLines',
  };
};

const createDocumentTotal = ({
  items = [],
  currency,
  locale,
}: DocumentTotal): DocumentContent => {
  const total = getTotalAmount(items);
  const subTotal = getSubTotalAmount(items);
  const defaultTaxRate = getAmountByDefaultTaxRate(items);
  const middleTaxRate = getAmountByMiddleTaxRate(items);

  const stack: DocumentContent = [
    {
      columns: [
        {
          text: t('invoiceGenerator.document.total.subtotal')(),
          style: {
            lineHeight: 1.6,
            color: colors.grey500,
            bold: true,
          },
        },
        {
          text: formatAmount(
            subTotal,
            locale,
            currency || DEFAULT_CURRENCY_CODE,
          ),
          style: { lineHeight: 1.6, alignment: 'right' },
        },
      ],
    },
  ];

  if (middleTaxRate) {
    stack.push({
      columns: [
        {
          text: `${t('invoiceGenerator.document.total.vat')()} ${VAT_MIDDLE_PERCENT}%`,
          style: {
            lineHeight: 1.6,
            color: colors.grey500,
            bold: true,
          },
        },
        {
          text: formatAmount(
            middleTaxRate,
            locale,
            currency || DEFAULT_CURRENCY_CODE,
          ),
          style: { lineHeight: 1.6, alignment: 'right' },
        },
      ],
    });
  }

  if (defaultTaxRate) {
    stack.push({
      columns: [
        {
          text: `${t('invoiceGenerator.document.total.vat')()} ${VAT_DEFAULT_PERCENT}%`,
          style: {
            lineHeight: 1.6,
            color: colors.grey500,
            bold: true,
          },
        },
        {
          text: formatAmount(
            defaultTaxRate,
            locale,
            currency || DEFAULT_CURRENCY_CODE,
          ),
          style: { lineHeight: 1.6, alignment: 'right' },
        },
      ],
    });
  }

  return [
    ...stack,
    {
      columns: [
        {
          text: t('invoiceGenerator.document.total.total')(),
          style: { lineHeight: 1.6, fontSize: 13, bold: true },
        },
        {
          text: formatAmount(total, locale, currency || DEFAULT_CURRENCY_CODE),
          style: {
            lineHeight: 1.6,
            fontSize: 13,
            bold: true,
            alignment: 'right',
          },
        },
      ],
    },
  ];
};

const createDocumentNotes = (text: string): DocumentContent => ({
  stack: [
    {
      text: t('invoiceGenerator.document.fieldNotes.label')(),
      style: {
        lineHeight: 1.6,
        fontSize: 13,
        bold: true,
      },
    },
    {
      style: {
        fontSize: 9,
      },
      text,
    },
  ],
  margin: [0, 20, 0, 20],
});

const createDocumentTermsAndConditions = (text: string): DocumentContent => ({
  stack: [
    {
      text: t('invoiceGenerator.document.fieldTerms.label')(),
      style: {
        lineHeight: 1.6,
        fontSize: 13,
        bold: true,
      },
    },
    {
      style: {
        fontSize: 9,
      },
      text,
    },
  ],
});

const createDocumentFooter = ({
  bankData,
  contactPerson,
}: DocumentFooter): TDocumentDefinitions['footer'] => ({
  columns: [
    {
      stack: [
        {
          text: `${t('invoiceGenerator.document.iban')()}: ${bankData?.iban || ''}`,
        },
        {
          text: `${t('invoiceGenerator.document.bic')()}: ${bankData?.bic || ''}`,
        },
      ],
      alignment: 'left',
    },
    {
      stack: [
        {
          text: contactPerson?.email || '',
        },
        {
          text: contactPerson?.phone || '',
        },
      ],
      alignment: 'right',
    },
  ],
  margin: [40, 30, 40, 40],
});

export const getDocumentDefinition = ({
  document,
  company,
  locale,
}: DocumentDefinition): TDocumentDefinitions => ({
  pageMargins: [40, 160, 40, 100],
  header: createDocumentHeader({
    number: document.documentMetadata?.number || '',
    companyName: company.name || '',
    issueDate: document.documentMetadata?.issueDate,
    dueDate: document.documentMetadata?.dueDate,
    deliveryDate: document.documentMetadata?.deliveryDate,
  }),
  footer: createDocumentFooter({
    bankData: company.details?.bankData,
    contactPerson: company.details?.contactPerson,
  }),
  content: [
    {
      columns: [
        {
          ...createDocumentTo(document.contact),
          width: '*',
        },
        {
          ...createDocumentFrom({
            companyName: company.name || '',
            details: company.details,
          }),
          width: 'auto',
        },
      ],
      columnGap: 32,
    },
    { text: '\n' },
    createDocumentTableItems({
      items: document.documentMetadata?.items,
      currency: document.documentMetadata?.currency,
      locale: locale as Language,
    }),
    createDocumentExtraText(document.documentMetadata?.items),
    {
      columns: [
        { text: '' },
        createDocumentTotal({
          items: document.documentMetadata?.items,
          currency: document.documentMetadata?.currency,
          locale: locale as Language,
        }),
      ],
    },
    document.documentMetadata?.notes
      ? createDocumentNotes(document.documentMetadata.notes)
      : ({} as DocumentContent),
    document.documentMetadata?.termsAndConditions
      ? createDocumentTermsAndConditions(
          document.documentMetadata.termsAndConditions,
        )
      : ({} as DocumentContent),
  ],
  defaultStyle: defaultStyle,
  styles: allStyles,
});
